import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import img1 from "../../Assets/comics/1.jpg";
import img2 from "../../Assets/comics/2.jpg";
import img3 from "../../Assets/comics/3.jpg";
import img4 from "../../Assets/comics/4.jpg";
import img5 from "../../Assets/comics/5.jpg";
import img6 from "../../Assets/comics/6.jpg";

const initialState = {
  mouseX: null,
  mouseY: null,
};

const useStyles = makeStyles((theme) => ({
  responsie: {
    width: "100%",
    maxWidth: "900px",
    height: "auto",
    display: "block",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 'auto',
  },
  menuButton1: {
    marginLeft: 'auto',
  },
  menuflex : {
    display: 'flex'
  },
  title: {
    flexGrow: 1,
  },
  appbarbg : {
      backgroundColor: '#DFDFDF'
  },
}));
export default function View() {
  const [state, setState] = React.useState(initialState);

  const handleClick = (event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setState(initialState);
  };
  const classes = useStyles();
  return (
    <div onContextMenu={handleClick} style={{ cursor: "context-menu" }}>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="md">
          <center>
            <div id="load_image_111">
              <img
                id="img-1"
                src={img1}
                className={classes.responsie}
                data-original={img1}
                alt="voomics comics"
              />
              <img
                id="img-1"
                src={img2}
                className={classes.responsie}
                data-original={img2}
                alt="voomics comics"
              />
              <img
                id="img-1"
                src={img3}
                className={classes.responsie}
                data-original={img3}
                alt="voomics comics"
              />
              <img
                id="img-1"
                src={img4}
                className={classes.responsie}
                data-original={img4}
                alt="voomics comics"
              />
              <img
                id="img-1"
                src={img5}
                className={classes.responsie}
                data-original={img5}
                alt="voomics comics"
              />
              <img
                id="img-1"
                src={img6}
                className={classes.responsie}
                data-original={img6}
                alt="voomics comics"
              />
            </div>
          </center>
          {/* <Typography component="div" style={{ backgroundColor: '#cfe8fc', height: '100vh', maxWidth: '900px' }} /> */}

          {/* prev and next navbar start */}
          <div className={classes.root}>
          <AppBar position="static" className={classes.appbarbg}>
            <Toolbar className={classes.menuflex}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="black"
                aria-label="prev"
              >
                <ArrowBackIcon />
              <Typography variant="h6">
                Prev
              </Typography>
              </IconButton>
              <IconButton
                edge="start"
                className={classes.menuButton1}
                color="black"
                aria-label="prev"
              >
              <Typography variant="h6">
                Next
              </Typography>
                <ArrowForwardIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          </div>
          {/* prev and next navbar end */}
        </Container>
      </React.Fragment>

      {/* Right Click Menu Items */}
      <Menu
        keepMounted
        open={state.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleClose}>Bookmark</MenuItem>
        <MenuItem onClick={handleClose}>Favorite</MenuItem>
        {/* <MenuItem onClick={handleClose}>Highlight</MenuItem>
        <MenuItem onClick={handleClose}>Email</MenuItem> */}
      </Menu>
    </div>
  );
}
