import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import moment from 'moment'
import logo from '../../Assets/voomics.png'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#212121'
  },
  maingrid: {
    color: 'gray',
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(5),
    // alignContent: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  _footer: {
        color: 'gray',
        textAlign: "center",
        backgroundColor: '#191919'
  }
}));    
export default function CenteredGrid() {
  const classes = useStyles();
  return (
    <div className={classes.root}><br/>
      <div className={classes.maingrid}>
            <img src={logo} alt="Voomics logo" />
            {/* <div>Voomics content are all protected by Copyright Law. Any unauthorized use, sharing or duplication may be punished by copyright laws.</div> */}
        {/* <Grid item xs={3}>
        </Grid>
        <Grid item xs={3}>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>xs=3</Paper>
        </Grid> */}
      </div><br/>
      <div className={classes.maingrid}> 
            <div style={{paddingLeft: '4px'}}>Voomics content are all protected by Copyright Law. Any unauthorized use, sharing or duplication may be punished by copyright laws.</div>
      </div>
      <br/><br/>
      <footer className={classes._footer}>
                         © {moment().format('YYYY')}
                         <Link  to="/" className=""> Voomics.com </Link>Tech |
                         <Link  to="/about" className=""> About Us </Link> |
                         <Link  to="/work" className=""> Work With Us </Link> |
                         <Link  to="/privacy" className=""> Privacy Policy </Link> |
                         <Link  to="/terms" className=""> Terms </Link>
       </footer>
    </div>
  );
}





















// import React, { Component } from 'react'
// import { Link } from 'react-router-dom'

// export default class Footer extends Component {
//     render() {
//         return (
//             <div>
//                 <footer className="">
//                         © 2020 Nasadiya Tech. Pvt. Ltd. |
//                         <Link  to="/about/pratilipi" className="">About Us</Link> |
//                         <Link  to="/work-with-us" className="">Work With Us</Link> |
//                         <Link  to="/privacy-policy" className="">Privacy Policy</Link> |
//                         <Link  to="/terms-of-service" className="">Terms</Link>
//                 </footer>
                
//             </div>
//         )
//     }
// }
