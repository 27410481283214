import React from 'react';
// import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from './Components/Home/Home';
import Login from './Components/Signin/Signin';
import SignUp from './Components/Signup/Signup'
import ContentDetails from './Components/Pages/ContentDetails'
import ContentList from './Components/Pages/ContentList';
import View from './Components/Pages/View';
import { PrivateRoute } from './Components/Routing/private-route';
import { useIsLoggedIn } from './Components/utilities';

function App() {
  useIsLoggedIn();
  return (
    <Router>
      {/* <PrivateRoute path='/' exact component={Dashboard} /> */}
      {/* <PrivateRoute path='/Dashboard' exact component={Dashboard} /> */}
      <Route path="/" exact component={Home} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={SignUp} />
      <Route path="/list" component={ContentList} />
      <Route path="/details" component={ContentDetails} />
      <PrivateRoute path="/view" component={View} />
    </Router>
  );
}

export default App;
