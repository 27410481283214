import { useSelector } from "react-redux";
import { UserSelector } from "./Slices/user-slice";

/**
* useUserSelector is a custom hook that returns the current state of the user object
* @returns {Object} - An object with the following properties:
*  - isAuthenticated {Boolean} - Indicates whether the user is authenticated or not.
*  - token {String} - The user's token.
*  - details {Object} - An object with the following properties:
*    - name {String} - The user's name.
*    - role {String} - The user's role.
*/
const useUserSelector = () => useSelector(UserSelector);

export {
    useUserSelector
}