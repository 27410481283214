import { configureStore } from '@reduxjs/toolkit'

// Redux Slices
import UserSlice from './Slices/user-slice';

const store = configureStore({
    reducer: {
        user: UserSlice
    }
});

export default store;