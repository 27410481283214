import React from 'react'
import { Link } from 'react-router-dom'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import img1 from '../../../Assets/1.png'
import img2 from '../../../Assets/2.png'
import img3 from '../../../Assets/3.png'
import img4 from '../../../Assets/4.png'
import img5 from '../../../Assets/5.png'
 
export default class Banner extends React.Component {
	constructor(props) {
		super();
		this.state = {
			galleryItems: [img1, img2, img3, img4, img5].map((i) => (
				<Link to='/'>
					<img
						src={i}
						alt='voomics-slider'
						width='100%'
					/>
				</Link>
			)),
		};
	}

	responsive = {
		0: { items: 1 },
		1024: { items: 2 },
	};

	onSlideChange(e) {
		console.debug('Item`s position during a change: ', e.item);
		console.debug('Slide`s position during a change: ', e.slide);
	}

	onSlideChanged(e) {
		console.debug('Item`s position after changes: ', e.item);
		console.debug('Slide`s position after changes: ', e.slide);
	}

	render() {
		return (
			<AliceCarousel
				items={this.state.galleryItems}
				responsive={this.responsive}
				autoPlayInterval={4000}
				autoPlayDirection='ltr'
				autoPlay={true}
				dotsDisabled={true}
				fadeOutAnimation={true}
				mouseTrackingEnabled={true}
				playButtonEnabled={false}
				buttonsDisabled={true}
				disableAutoPlayOnAction={true}
				onSlideChange={this.onSlideChange}
				onSlideChanged={this.onSlideChanged}
			/>
		);
	}
}
















// import React from 'react'
// import AliceCarousel from 'react-alice-carousel'
// import 'react-alice-carousel/lib/alice-carousel.css'
// import img1 from '../../../Assets/1.png'
// import img2 from '../../../Assets/2.png'
// import img3 from '../../../Assets/3.png'
// import img4 from '../../../Assets/4.png'
// import img5 from '../../../Assets/5.png'
 
// const Banner = () => {
//   const handleOnDragStart = (e) => e.preventDefault()
//   return (
//     <AliceCarousel mouseTrackingEnabled responsive={this.responsive}
//     autoPlayInterval={2000}
//     autoPlayDirection="rtl"
//     autoPlay={true}
//     fadeOutAnimation={true}
//     mouseTrackingEnabled={true}
//     playButtonEnabled={true}
//     disableAutoPlayOnAction={true}>
//       <img src={img1} alt='voomics-slider' onDragStart={handleOnDragStart} className="yours-custom-class" />
//       <img src={img2} alt='voomics-slider' onDragStart={handleOnDragStart} className="yours-custom-class" />
//       <img src={img3} alt='voomics-slider' onDragStart={handleOnDragStart} className="yours-custom-class" />
//       <img src={img4} alt='voomics-slider' onDragStart={handleOnDragStart} className="yours-custom-class" />
//       <img src={img5} alt='voomics-slider' onDragStart={handleOnDragStart} className="yours-custom-class" />
//     </AliceCarousel>
//   )
// }
// export default Banner;