import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import img1 from "../../Assets/Details/1.jpg";
import img2 from "../../Assets/Details/2.png";
import ContentTab from "./ContentTab.jsx";


const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  

  export default function ContentDetails (props) {
    const classes = useStyles();
    return (
      <div>
        <div
          style={{
            backgroundImage: `url(${img1})`,
            minHeight: "322px",
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover !important",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${img2})`,
              width: "1200px",
              margin: "0 auto",
              minHeight: "322px",
            }}
          >
            <div
              style={{
                color: "#fff",
                width: "100%",
                height: "322px",
                display: "table-cell",
                verticalAlign: "middle",
              }}
            >
              <div style={{ marginLeft: "60px" }}>
                <p></p>
                <h1>Shaktiman</h1>
                <h3>
                  What does a bellboy do when the vvip guest <br />
                  at his hotel starts showing interest in him?
                </h3>
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    // startIcon={<DeleteIcon />}
                  >
                    Read Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContentTab onClickingtoChild = {props.onClickingtoChild}/>
      </div>
    );
  }
