import React from 'react';
import { useMutation } from 'react-query';
import { SERVER } from '../utilities';

export function useSubmitLoginForm({ onSuccess, onError }) {
    const submitForm = useMutation(async (variables) => {
        const response = await fetch(`${SERVER}/v1/auth/login`, {
            method: 'POST',
            body: JSON.stringify(variables),
            headers: { 'Content-Type': 'application/json' },
        });
        return response.json();
    }, {
        onSuccess,
        onError
    });

    return [submitForm]
}

export function useSubmitSignUpForm({ onSuccess, onError }) {

    const submitForm = useMutation(async (variables) => {
        const response = await fetch(`${SERVER}/v1/auth/register`, {
            method: 'POST',
            body: JSON.stringify(variables),
            headers: { 'Content-Type': 'application/json' },
        });
        return response.json();
    }, {
        onSuccess,
        onError
    });

    return [submitForm]
}