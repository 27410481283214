import { createSlice } from '@reduxjs/toolkit'

// Initial User state

const INIT_STATE = {
    isAuthenticated: false,
    tokens: {
        access: '',
        refresh: ''
    },
    details: {
        id: '',
        name: '',
        role: '',
    }
}

export const UserSlice = createSlice({
    name: 'user',
    initialState: INIT_STATE,
    reducers: {
        saveUser: (state, action) => {
            // for (let key in action.payload) {
            //     state[key] = action.payload[key];
            // }
            // alert(JSON.stringify(action.payload, null, 2));
            state.isAuthenticated = true
        },
        logUserOut: (state) => {
            for (let key in INIT_STATE) {
                state[key] = INIT_STATE[key];
            }
        }
    },
})

const userActions = UserSlice.actions;
export {
    userActions
}
export const UserSelector = (state) => state.user
export default UserSlice.reducer