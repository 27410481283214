import React from 'react'
import { Route } from "react-router-dom";
import { useUserSelector } from '../Redux/hooks';
import SignIn from '../Signin/Signin';
import { useIsLoggedIn } from '../utilities';


/**
* PrivateRoute is a component that renders a Route component if the user is authenticated, otherwise it renders a fallback component.
*
* @param {Object} props - The component props.
* @param {string} props.path - The path that the Route component should match.
* @param {React.Component} props.component - The component that should be rendered if the user is authenticated.
* @param {React.Component} [props.componentIfNotAuthenticated=<SignIn />] - The component that should be rendered if the user is not authenticated.
* @param {Object} [props.others] - Other props that should be passed to the Route component.
*
* @returns {React.Component} - The Route component.
*/

export function PrivateRoute({ path, component, componentIfNotAuthenticated = SignIn, ...props }) {
    const { isAuthenticated } = useUserSelector();

    if (isAuthenticated) {
        return <Route path={path} component={component} />
    }
    return <Route path={path} component={componentIfNotAuthenticated} />
}

/**
* ProtectedComponent is a component that renders its children if the user is authenticated, otherwise it renders a fallback component.
*
* @param {Object} props - The component props.
* @param {React.Node} props.children - The children to be rendered if the user is authenticated.
* @param {React.Component} [props.componentIfNotAuthenticated=null] - The component that should be rendered if the user is not authenticated.
* @param {Object} [props.others] - Other props that should be passed to the component.
*
* @returns {React.Node|React.Component} - The children or fallback component.
*/

export const ProtectedComponent = ({ children, componentIfNotAuthenticated = null, ...props }) => {
    const { isAuthenticated } = useUserSelector();
    if (isAuthenticated) {
        return (
            <>
                {children}
            </>
        )
    }
    return componentIfNotAuthenticated
}
