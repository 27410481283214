import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import FavoriteBorderRoundedIcon from "@material-ui/icons/FavoriteBorderRounded";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import UpIcon from "@material-ui/icons/KeyboardArrowUp";
import { green } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CircularProgress from "@material-ui/core/CircularProgress";
// import ListItemText from "@material-ui/core/ListItemText";
import img1 from "../../Assets/smcard/1.jpg";
import img2 from "../../Assets/smcard/2.jpg";
import img3 from "../../Assets/smcard/3.jpg";
import img4 from "../../Assets/Card/4.jpg";
import img5 from "../../Assets/Card/5.jpg";
import Card from "@material-ui/core/Card";
import ContentList from "./ContentList";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     maxWidth: 360,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  imgClass: {
    width: "160px",
    height: "96px",
    paddingRight: "50px",
  },
  navHeight: {
    minHeight: 60,
  },
  mainbody: {
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(5),
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    position: "relative",
    minHeight: 300,
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

export default function FloatingActionButtonZoom(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fabs = [
    {
      color: "primary",
      className: classes.fab,
      icon: <AddIcon />,
      label: "Add",
    },
    {
      color: "secondary",
      className: classes.fab,
      icon: <EditIcon />,
      label: "Edit",
    },
    {
      color: "inherit",
      className: clsx(classes.fab, classes.fabGreen),
      icon: <UpIcon />,
      label: "Expand",
    },
  ];

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <div className={classes.mainbody}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="action tabs example"
          >
            <Tab
              label="Episods"
              {...a11yProps(0)}
              className={classes.navHeight}
            />
            <Tab
              label="Descriptions"
              {...a11yProps(1)}
              className={classes.navHeight}
            />
            <Tab
              label="Related"
              {...a11yProps(2)}
              className={classes.navHeight}
            />
          </Tabs>
        </div>
      </AppBar>
      {/* <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        className={classes.mainbody}
      > */}
      <TabPanel value={value} index={0} dir={theme.direction}>
        {/* Episodes tab content start */}
        <List component="nav" aria-label="Details page nav bar">
          <ListItem button style={{ display: "flex" }}>
            <img src={img1} alt="" className={classes.imgClass} />
            {/* <ListItemText primary="Ep.1" /> */}
            <h3 style={{ width: "9%" }}>Ep.1</h3>
            <h4 style={{ width: "16%" }}>God Of Shakti</h4>
            <p style={{ width: "16%" }}>Apr 7, 2020</p>
            <p style={{ width: "16%" }}>View 2.5M</p>
            <FavoriteBorderRoundedIcon /> <p style={{ width: "16%" }}> 28000</p>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: "auto" }}
            >
              Free in App
            </Button>
          </ListItem>
          <ListItem button style={{ display: "flex" }}>
            <img src={img1} alt="" className={classes.imgClass} />
            {/* <ListItemText primary="Ep.1" /> */}
            <h3 style={{ width: "9%" }}>Ep.1</h3>
            <h4 style={{ width: "16%" }}>God Of Shakti</h4>
            <p style={{ width: "16%" }}>Apr 7, 2020</p>
            <p style={{ width: "16%" }}>View 2.5M</p>
            <FavoriteBorderRoundedIcon /> <p style={{ width: "16%" }}> 28000</p>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: "auto" }}
            >
              Free in App
            </Button>
          </ListItem>
          <ListItem button style={{ display: "flex" }}>
            <img src={img1} alt="" className={classes.imgClass} />
            {/* <ListItemText primary="Ep.1" /> */}
            <h3 style={{ width: "9%" }}>Ep.1</h3>
            <h4 style={{ width: "16%" }}>God Of Shakti</h4>
            <p style={{ width: "16%" }}>Apr 7, 2020</p>
            <p style={{ width: "16%" }}>View 2.5M</p>
            <FavoriteBorderRoundedIcon /> <p style={{ width: "16%" }}> 28000</p>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: "auto" }}
            >
              Free in App
            </Button>
          </ListItem>
          <ListItem button style={{ display: "flex" }}>
            <img src={img1} alt="" className={classes.imgClass} />
            {/* <ListItemText primary="Ep.1" /> */}
            <h3 style={{ width: "9%" }}>Ep.1</h3>
            <h4 style={{ width: "16%" }}>God Of Shakti</h4>
            <p style={{ width: "16%" }}>Apr 7, 2020</p>
            <p style={{ width: "16%" }}>View 2.5M</p>
            <FavoriteBorderRoundedIcon /> <p style={{ width: "16%" }}> 28000</p>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: "auto" }}
            >
              Free in App
            </Button>
          </ListItem>
          <ListItem button style={{ display: "flex" }}>
            <img src={img1} alt="" className={classes.imgClass} />
            {/* <ListItemText primary="Ep.1" /> */}
            <h3 style={{ width: "9%" }}>Ep.1</h3>
            <h4 style={{ width: "16%" }}>God Of Shakti</h4>
            <p style={{ width: "16%" }}>Apr 7, 2020</p>
            <p style={{ width: "16%" }}>View 2.5M</p>
            <FavoriteBorderRoundedIcon /> <p style={{ width: "16%" }}> 28000</p>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: "auto" }}
            >
              Free in App
            </Button>
          </ListItem>
          <ListItem button style={{ display: "flex" }}>
            <img src={img1} alt="" className={classes.imgClass} />
            {/* <ListItemText primary="Ep.1" /> */}
            <h3 style={{ width: "9%" }}>Ep.1</h3>
            <h4 style={{ width: "16%" }}>God Of Shakti</h4>
            <p style={{ width: "16%" }}>Apr 7, 2020</p>
            <p style={{ width: "16%" }}>View 2.5M</p>
            <FavoriteBorderRoundedIcon /> <p style={{ width: "16%" }}> 28000</p>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: "auto" }}
            >
              Free in App
            </Button>
          </ListItem>
          <ListItem button style={{ display: "flex" }}>
            <img src={img1} alt="" className={classes.imgClass} />
            {/* <ListItemText primary="Ep.1" /> */}
            <h3 style={{ width: "9%" }}>Ep.1</h3>
            <h4 style={{ width: "16%" }}>God Of Shakti</h4>
            <p style={{ width: "16%" }}>Apr 7, 2020</p>
            <p style={{ width: "16%" }}>View 2.5M</p>
            <FavoriteBorderRoundedIcon /> <p style={{ width: "16%" }}> 28000</p>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: "auto" }}
            >
              Free in App
            </Button>
          </ListItem>
          <ListItem button style={{ display: "flex" }}>
            <img src={img1} alt="" className={classes.imgClass} />
            {/* <ListItemText primary="Ep.1" /> */}
            <h3 style={{ width: "9%" }}>Ep.1</h3>
            <h4 style={{ width: "16%" }}>God Of Shakti</h4>
            <p style={{ width: "16%" }}>Apr 7, 2020</p>
            <p style={{ width: "16%" }}>View 2.5M</p>
            <FavoriteBorderRoundedIcon /> <p style={{ width: "16%" }}> 28000</p>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: "auto" }}
            >
              Free in App
            </Button>
          </ListItem>
          <ListItem button style={{ display: "flex" }}>
            <img src={img1} alt="" className={classes.imgClass} />
            {/* <ListItemText primary="Ep.1" /> */}
            <h3 style={{ width: "9%" }}>Ep.1</h3>
            <h4 style={{ width: "16%" }}>God Of Shakti</h4>
            <p style={{ width: "16%" }}>Apr 7, 2020</p>
            <p style={{ width: "16%" }}>View 2.5M</p>
            <FavoriteBorderRoundedIcon /> <p style={{ width: "16%" }}> 28000</p>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: "auto" }}
            >
              Free in App
            </Button>
          </ListItem>
        </List>
        <center>
          <CircularProgress />
        </center>
        {/* Episodes tab content end */}
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        {/* Description Tab */}
        <p>
          A starship is struck by an asteroid on its way to colonize a distant
          planet. Now, hundreds of years later, the inhabitants must learn to
          survive deep space without technology or perish. With the ship split
          in two and the citizens fragmented, disaster is imminent. Join
          princess Airomem and Horatius the historian on their adventures- from
          fighting cannibals, to preventing starvation, to discovering long
          hidden secrets of the ship. Can they survive? Or will the thousand
          year journey end in failure? With each passing year the ship degrades,
          natural disaster combined with inept leaders lead to famine, and one
          day, the ship intercom crackles to life and announces the two halves
          are joining back together. Horatius must dig into the secrets of the
          past to save his people from themselves.
        </p>
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <ContentList />
      </TabPanel>
      {/* </SwipeableViews> */}
      {fabs.map((fab, index) => (
        <Zoom
          key={fab.color}
          in={value === index}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${
              value === index ? transitionDuration.exit : 0
            }ms`,
          }}
          unmountOnExit
        >
          <Fab
            aria-label={fab.label}
            className={fab.className}
            color={fab.color}
          >
            {fab.icon}
          </Fab>
        </Zoom>
      ))}
    </div>
  );
}
