import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import img1 from '../../Assets/Card/1.jpg'
import Cardlg from '../Card/Cardlg'

export default class Trending extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             details: {}
        }
    }
    
    componentDidMount = () => {
        let title = 'Saktiman';
        let description = 'This is a story about a boy who can hear every'
        let detail = {
            img1,
            title,
            description
        }
        this.setState({
            details: detail
        })
    }
    render() {
        return (
            <div>
                <div style={{ display: 'flex' }}>
                    <h1 style={{ marginRight: "auto", textDecoration: 'underline overline double red' }}>Trending List</h1>
                </div>
                <div style={{ display: 'flex' }}>
                    <Link style={{ marginLeft: "auto" }} to='/' >See more..</Link>
                </div>
                <div style={{display: 'inline-flex'}}>
                    <div style={{paddingRight: '15px', paddingTop: '15px', paddingBottom: '15px'}}>
                    <Cardlg details={this.state.details} />
                    </div>
                    <div style={{padding: '15px'}}>
                    <Cardlg details={this.state.details} />
                    </div>
                    <div style={{padding: '15px'}}>
                    <Cardlg details={this.state.details} />
                    </div>
                    <div style={{padding: '15px'}}>
                    <Cardlg details={this.state.details} />
                    </div>
                    <div style={{padding: '15px'}}>
                    <Cardlg details={this.state.details} />
                    </div>
                </div>
                <div style={{display: 'inline-flex'}}>
                    <div style={{paddingRight: '15px', paddingTop: '15px', paddingBottom: '15px'}}>
                    <Cardlg details={this.state.details} />
                    </div>
                    <div style={{padding: '15px'}}>
                    <Cardlg details={this.state.details} />
                    </div>
                    <div style={{padding: '15px'}}>
                    <Cardlg details={this.state.details} />
                    </div>
                    <div style={{padding: '15px'}}>
                    <Cardlg details={this.state.details} />
                    </div>
                    <div style={{padding: '15px'}}>
                    <Cardlg details={this.state.details} />
                    </div>
                </div>
            </div>
        )
    }
}
