import React, { Component } from "react";
import { Link } from "react-router-dom";
import Cardsm from "../Card/Cardsm";
import img1 from "../../Assets/Card/1.jpg";
import img2 from "../../Assets/Card/2.jpg";
import img3 from "../../Assets/Card/3.png";
import img4 from "../../Assets/Card/4.jpg";
import img5 from "../../Assets/Card/5.jpg";
import img6 from "../../Assets/Card/6.jpg";
import CircularProgress from "@material-ui/core/CircularProgress";


export default class ContentList extends Component {
  render() {
    return (
      <div>
        {/* <div style={{ display: 'flex' }}>
                    <h1 style={{ marginRight: "auto" }}>New List</h1>
                </div>
                <div style={{ display: 'flex' }}>
                    <Link style={{ marginLeft: "auto" }} to='/' >See more..</Link>
                </div> */}
        <div style={{ display: "inline-flex" }}>
          <div
            style={{
              paddingRight: "15px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <Cardsm img={img1} />
          </div>
          <div style={{ padding: "15px" }}>
            <Cardsm img={img2} />
          </div>
          <div style={{ padding: "15px" }}>
            <Cardsm img={img3} />
          </div>
          <div style={{ padding: "15px" }}>
            <Cardsm img={img4} />
          </div>
          <div style={{ padding: "15px" }}>
            <Cardsm img={img5} />
          </div>
          <div style={{ padding: "15px" }}>
            <Cardsm img={img6} />
          </div>
        </div>
        <div style={{ display: "inline-flex" }}>
          <div
            style={{
              paddingRight: "15px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <Cardsm img={img1} />
          </div>
          <div style={{ padding: "15px" }}>
            <Cardsm img={img2} />
          </div>
          <div style={{ padding: "15px" }}>
            <Cardsm img={img3} />
          </div>
          <div style={{ padding: "15px" }}>
            <Cardsm img={img4} />
          </div>
          <div style={{ padding: "15px" }}>
            <Cardsm img={img5} />
          </div>
          <div style={{ padding: "15px" }}>
            <Cardsm img={img6} />
          </div>
        </div>
        <center>
          <CircularProgress />
        </center>
      </div>
    );
  }
}
