import React, { Component } from "react";
import { Link } from "react-router-dom";
import Cardsm from "../Card/Cardsm";
import img1 from "../../Assets/Card/1.jpg";
import img2 from "../../Assets/Card/2.jpg";
import img3 from "../../Assets/Card/3.png";
import img4 from "../../Assets/Card/4.jpg";
import img5 from "../../Assets/Card/5.jpg";
import img6 from "../../Assets/Card/6.jpg";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

// export default class New extends Component {
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function New() {
	const classes = useStyles();

	// <div>
	//     <div style={{ display: 'flex' }}>
	//         <h1 style={{ marginRight: "auto",  textDecoration: 'underline overline double red' }}>New List</h1>
	//     </div>
	//     <div style={{ display: 'flex' }}>
	//         <Link style={{ marginLeft: "auto" }} to='/list' onClick= { () => this.props.onClickingtoChild(2)}>See more..</Link>
	//     </div>
	//     <div style={{display: 'inline-flex'}}>
	//         <div style={{paddingRight: '15px', paddingTop: '15px', paddingBottom: '15px'}}>
	//             <Link to ='/'  onClick= { () => this.props.onClickingtoChild(3)}>
	//                 <Cardsm img= {img1}/>
	//             </Link>
	//         </div>
	//         <div style={{padding: '15px'}}>
	//             <Cardsm img= {img2}/>
	//         </div>
	//         <div style={{padding: '15px'}}>
	//             <Cardsm img= {img3} />
	//         </div>
	//         <div style={{padding: '15px'}}>
	//             <Cardsm img= {img4} />
	//         </div>
	//         <div style={{padding: '15px'}}>
	//             <Cardsm img= {img5} />
	//         </div>
	//         <div style={{padding: '15px'}}>
	//             <Cardsm img= {img6} />
	//         </div>
	//     </div>
	//     <div style={{display: 'inline-flex'}}>
	//         <div style={{paddingRight: '15px', paddingTop: '15px', paddingBottom: '15px'}}>
	//             <Cardsm img= {img1}/>
	//         </div>
	//         <div style={{padding: '15px'}}>
	//             <Cardsm img= {img2}/>
	//         </div>
	//         <div style={{padding: '15px'}}>
	//             <Cardsm img= {img3} />
	//         </div>
	//         <div style={{padding: '15px'}}>
	//             <Cardsm img= {img4} />
	//         </div>
	//         <div style={{padding: '15px'}}>
	//             <Cardsm img= {img5} />
	//         </div>
	//         <div style={{padding: '15px'}}>
	//             <Cardsm img= {img6} />
	//         </div>
	//     </div>
	// </div>
	return (
		<div className={classes.root}>
			<div style={{ display: 'flex' }}>
				<h1
					style={{
						marginRight: 'auto',
						textDecoration: 'underline overline double red',
					}}>
					New List
				</h1>
			</div>
			<div style={{ display: 'flex' }}>
				<Link
					style={{ marginLeft: 'auto' }}
					to='/list'
					onClick={() => this.props.onClickingtoChild(2)}>
					See more..
				</Link>
			</div>
			<Grid
				container
				spacing={2}>
				<Grid
					item
					xs={2}>
					<Cardsm img={img1} />
				</Grid>
				<Grid
					item
					xs={2}>
					<Cardsm img={img2} />
				</Grid>
				<Grid
					item
					xs={2}>
					<Cardsm img={img3} />
				</Grid>
				<Grid
					item
					xs={2}>
					<Cardsm img={img4} />
				</Grid>
				<Grid
					item
					xs={2}>
					<Cardsm img={img5} />
				</Grid>
				<Grid
					item
					xs={2}>
					<Cardsm img={img6} />
				</Grid>
			</Grid>
			<Grid
				container
				spacing={2}>
				<Grid
					item
					xs={2}>
					<Cardsm img={img1} />
				</Grid>
				<Grid
					item
					xs={2}>
					<Cardsm img={img2} />
				</Grid>
				<Grid
					item
					xs={2}>
					<Cardsm img={img3} />
				</Grid>
				<Grid
					item
					xs={2}>
					<Cardsm img={img4} />
				</Grid>
				<Grid
					item
					xs={2}>
					<Cardsm img={img5} />
				</Grid>
				<Grid
					item
					xs={2}>
					<Cardsm img={img6} />
				</Grid>
			</Grid>
		</div>
	);
}
// }
