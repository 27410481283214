import React, { Component } from 'react'
import Navbar from '../Header/Navbar/Navbar';
// import Banner from '../Header/Banner/Banner'
import Footer from '../Footer/Footer';
import Body from './Body'
import ContentList from '../Pages/ContentList'
import ContentDetails from '../Pages/ContentDetails';
import View from '../Pages/View';

export default class Home extends Component {
    constructor(props) {
        super();
    
        this.state = {
					mainComponent: <Body onClickingtoChild={this.onClickingtoChild} />,
				};
    }

    onClickingtoChild = (key) => {
        switch (key) {
            case 1:
                this.setState({
                    mainComponent : <Body  onClickingtoChild = {this.onClickingtoChild} />
                })
                break;
            case 2:
                this.setState({
                    mainComponent : <ContentList />
                })
                break;
            case 3:
                this.setState({
                    mainComponent : <ContentDetails onClickingtoChild = {this.onClickingtoChild} />
                })
                break;
            case 4:
                this.setState({
                    mainComponent : <View />
                })
                break;
            
            // case 2:
            //     this.setState({
            //         mainComponent : <SubAdmin onClickEditDeleteViewBlockInSubadmin = { this.onClickEditDeleteViewBlockInSubadmin } />
            //     })
            //     break;
            // case 21:
            //     this.setState({
            //         mainComponent : <SubAdminAdd user={this.state.user}/>
            //     })
            //     break;
            // case 22:
            //     this.setState({
            //         mainComponent : <SubAdminView />
            //     })
            //     break;
            // case 23:
            //     this.setState({
            //         mainComponent : <SubAdminEdit />
            //     })
            //     break;
            // case 3:
            //     this.setState({
            //         mainComponent : <ProductsList />
            //     })
            //     break;
            // case 31:
            //     this.setState({
            //         mainComponent : <Products />
            //     })
            //     break;
            // case 32:
            //     this.setState({
            //         mainComponent : <ProductAdd user={this.state.user} />
            //     })
            //     break;
            // case 4:
            //     this.setState({
            //         mainComponent : <Discount />
            //     })
            //     break;

            // case 5:
            //     this.setState({
            //         mainComponent : <Orders />
            //     })
            //     break;

            // case 6:
            //     this.setState({
            //         mainComponent : <Customers />
            //     })
            //     break;

            // case 7:
            //     this.setState({
            //         mainComponent : <PinCode />
            //     })
            //     break;

            // case 8:
            //     this.setState({
            //         mainComponent : <Transactions />
            //     })
            //     break;

            // case 9:
            //     this.setState({
            //         mainComponent : <OrderTypeSettings />
            //     })
            //     break;

            // case 10:
            //     this.setState({
            //         mainComponent : <AppSettings />
            //     })
            //     break;

            default:
                this.setState({
                    mainComponent : <Body  onClickingtoChild = {this.onClickingtoChild} />
                })
                break;
        }
    }
    
    render() {
        return (
					<div>
						<Navbar onClickingtoChild={this.onClickingtoChild} />
						{/* <Banner /><br/><br/> */}
						{this.state.mainComponent}
						<Footer />
					</div>
				);
    }
}
