import React from 'react';
import { useDispatch } from 'react-redux';
import { userActions, UserSelector } from './Redux/Slices/user-slice';

export const ENV = process.env.REACT_APP_ENV
export const SERVER = ENV === 'DEV' ? process.env.REACT_APP_DEV_BACKEND : process.env.REACT_APP_PROD_BACKEND
/**
 * useIsLoggedIn is a custom hook in React that determines if a user is logged in based on the access token and its expiration date stored in local storage.
 * @param {Array} deps - Optional dependencies that trigger a re-render when they change.
 *
 * @returns {void}
 */
export const useIsLoggedIn = (...deps) => {
    const notAcceptedTokens = [null, false, '']
    const dispatch = useDispatch(UserSelector);
    React.useEffect(() => {
        let userDetails = localStorage.getItem('User-Details');
        if (userDetails === null || typeof userDetails !== 'string') {
            return () => {
                dispatch(userActions.logUserOut());
            }
        }
        userDetails = JSON.parse(userDetails);

        let { token, expires } = userDetails?.tokens.access

        if (ENV === "DEV") {
            alert(JSON.stringify(userDetails, null, 2));
        }
        const givenTimestamp = new Date(expires);
        const currentTime = new Date();

        if (currentTime > givenTimestamp) {
            return () => {
                dispatch(userActions.logUserOut());
            }
        }
        if (typeof token !== 'string' || notAcceptedTokens.includes(token)) {
            return () => {
                dispatch(userActions.logUserOut());
            }
        }
        dispatch(userActions.saveUser(userDetails));

    }, deps);
}